// @generated by protobuf-ts 2.0.0-alpha.27 with parameters long_type_number
// @generated from protobuf file "service.proto" (package "ngld.knossos", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Release } from "./mod";
import { Timestamp } from "./google/protobuf/timestamp";
// General types

/**
 * @generated from protobuf message ngld.knossos.BoolResponse
 */
export interface BoolResponse {
    /**
     * @generated from protobuf field: bool success = 1;
     */
    success: boolean;
}
/**
 * @generated from protobuf message ngld.knossos.TokenRequest
 */
export interface TokenRequest {
    /**
     * required
     *
     * @generated from protobuf field: string token = 1;
     */
    token: string;
}
/**
 * @generated from protobuf message ngld.knossos.TokenResponse
 */
export interface TokenResponse {
    /**
     * @generated from protobuf field: bool success = 1;
     */
    success: boolean;
    /**
     * @generated from protobuf field: string token = 2;
     */
    token: string;
}
/**
 * @generated from protobuf message ngld.knossos.NullRequest
 */
export interface NullRequest {
}
// Specific messages

/**
 * @generated from protobuf message ngld.knossos.LoginRequest
 */
export interface LoginRequest {
    /**
     * required
     *
     * @generated from protobuf field: string username = 1;
     */
    username: string;
    /**
     * required
     *
     * @generated from protobuf field: string password = 2;
     */
    password: string;
}
/**
 * @generated from protobuf message ngld.knossos.LoginResponse
 */
export interface LoginResponse {
    /**
     * @generated from protobuf field: string token = 1;
     */
    token: string;
    /**
     * @generated from protobuf field: bool success = 2;
     */
    success: boolean;
}
/**
 * @generated from protobuf message ngld.knossos.SessionResponse
 */
export interface SessionResponse {
    /**
     * @generated from protobuf field: bool valid = 1;
     */
    valid: boolean;
    /**
     * @generated from protobuf field: string username = 2;
     */
    username: string;
}
/**
 * @generated from protobuf message ngld.knossos.RegisterRequest
 */
export interface RegisterRequest {
    /**
     * required
     *
     * @generated from protobuf field: string username = 1;
     */
    username: string;
    /**
     * required
     *
     * @generated from protobuf field: string email = 2;
     */
    email: string;
    /**
     * required
     *
     * @generated from protobuf field: string password = 3;
     */
    password: string;
}
/**
 * @generated from protobuf message ngld.knossos.StartPasswordResetRequest
 */
export interface StartPasswordResetRequest {
    /**
     * required
     *
     * @generated from protobuf field: string email = 1;
     */
    email: string;
}
/**
 * @generated from protobuf message ngld.knossos.ResetPasswordRequest
 */
export interface ResetPasswordRequest {
    /**
     * required
     *
     * @generated from protobuf field: string token = 1;
     */
    token: string;
    /**
     * required
     *
     * @generated from protobuf field: string password = 2;
     */
    password: string;
}
/**
 * @generated from protobuf message ngld.knossos.ModListRequest
 */
export interface ModListRequest {
    /**
     * required
     *
     * @generated from protobuf field: int32 offset = 1;
     */
    offset: number;
    /**
     * @generated from protobuf field: int32 limit = 2;
     */
    limit: number;
    /**
     * @generated from protobuf field: string query = 3;
     */
    query: string;
    /**
     * @generated from protobuf field: ngld.knossos.ModListRequest.SortType sort = 4;
     */
    sort: ModListRequest_SortType;
}
/**
 * @generated from protobuf enum ngld.knossos.ModListRequest.SortType
 */
export enum ModListRequest_SortType {
    /**
     * @generated from protobuf enum value: NAME = 0;
     */
    NAME = 0
}
/**
 * @generated from protobuf message ngld.knossos.ModListItem
 */
export interface ModListItem {
    /**
     * @generated from protobuf field: string modid = 1;
     */
    modid: string;
    /**
     * @generated from protobuf field: string teaser = 2;
     */
    teaser: string;
    /**
     * @generated from protobuf field: string title = 3;
     */
    title: string;
    /**
     * @generated from protobuf field: string version = 5;
     */
    version: string;
    /**
     * @generated from protobuf field: uint32 release_count = 4;
     */
    releaseCount: number;
}
/**
 * @generated from protobuf message ngld.knossos.ModListResponse
 */
export interface ModListResponse {
    /**
     * @generated from protobuf field: int32 count = 1;
     */
    count: number;
    /**
     * @generated from protobuf field: repeated ngld.knossos.ModListItem mods = 2;
     */
    mods: ModListItem[];
}
/**
 * @generated from protobuf message ngld.knossos.ModDetailsRequest
 */
export interface ModDetailsRequest {
    /**
     * required
     *
     * @generated from protobuf field: string modid = 1;
     */
    modid: string;
    /**
     * @generated from protobuf field: bool latest = 2;
     */
    latest: boolean;
    /**
     * @generated from protobuf field: string version = 3;
     */
    version: string;
    /**
     * @generated from protobuf field: bool request_downloads = 4;
     */
    requestDownloads: boolean;
}
/**
 * @generated from protobuf message ngld.knossos.ModDownloadArchive
 */
export interface ModDownloadArchive {
    /**
     * @generated from protobuf field: string label = 1;
     */
    label: string;
    /**
     * @generated from protobuf field: string checksum = 2;
     */
    checksum: string;
    /**
     * @generated from protobuf field: uint32 size = 3;
     */
    size: number;
    /**
     * @generated from protobuf field: repeated string links = 4;
     */
    links: string[];
}
/**
 * @generated from protobuf message ngld.knossos.ModDownloadPackage
 */
export interface ModDownloadPackage {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: string notes = 2;
     */
    notes: string;
    /**
     * @generated from protobuf field: repeated ngld.knossos.ModDownloadArchive archives = 3;
     */
    archives: ModDownloadArchive[];
}
/**
 * @generated from protobuf message ngld.knossos.ModDetailsResponse
 */
export interface ModDetailsResponse {
    /**
     * @generated from protobuf field: string title = 1;
     */
    title: string;
    /**
     * @generated from protobuf field: string version = 2;
     */
    version: string;
    /**
     * @generated from protobuf field: uint32 type = 3;
     */
    type: number;
    /**
     * @generated from protobuf field: uint32 stability = 4;
     */
    stability: number;
    /**
     * @generated from protobuf field: string description = 5;
     */
    description: string;
    /**
     * @generated from protobuf field: string banner = 6;
     */
    banner: string;
    /**
     * @generated from protobuf field: string release_thread = 7;
     */
    releaseThread: string;
    /**
     * @generated from protobuf field: repeated string screenshots = 8;
     */
    screenshots: string[];
    /**
     * @generated from protobuf field: repeated string videos = 9;
     */
    videos: string[];
    /**
     * @generated from protobuf field: google.protobuf.Timestamp released = 10;
     */
    released?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp updated = 11;
     */
    updated?: Timestamp;
    /**
     * @generated from protobuf field: repeated string versions = 12;
     */
    versions: string[];
    /**
     * @generated from protobuf field: repeated ngld.knossos.ModDownloadPackage downloads = 13;
     */
    downloads: ModDownloadPackage[];
}
/**
 * @generated from protobuf message ngld.knossos.ModInstallRequest
 */
export interface ModInstallRequest {
    /**
     * @generated from protobuf field: string modid = 1;
     */
    modid: string;
    /**
     * @generated from protobuf field: string version = 2;
     */
    version: string;
}
/**
 * @generated from protobuf message ngld.knossos.ModInstallResponse
 */
export interface ModInstallResponse {
    /**
     * @generated from protobuf field: string error = 1;
     */
    error: string;
    /**
     * a list containing the requested mod and all dependencies
     *
     * @generated from protobuf field: repeated ngld.knossos.Release releases = 2;
     */
    releases: Release[];
}
/**
 * @generated from protobuf message ngld.knossos.VersionsResponse
 */
export interface VersionsResponse {
    /**
     * @generated from protobuf field: map<string, string> versions = 1;
     */
    versions: {
        [key: string]: string;
    };
}
// @generated message type with reflection information, may provide speed optimized methods
class BoolResponse$Type extends MessageType<BoolResponse> {
    constructor() {
        super("ngld.knossos.BoolResponse", [
            { no: 1, name: "success", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BoolResponse): BoolResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool success */ 1:
                    message.success = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BoolResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool success = 1; */
        if (message.success !== false)
            writer.tag(1, WireType.Varint).bool(message.success);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ngld.knossos.BoolResponse
 */
export const BoolResponse = new BoolResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TokenRequest$Type extends MessageType<TokenRequest> {
    constructor() {
        super("ngld.knossos.TokenRequest", [
            { no: 1, name: "token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TokenRequest): TokenRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string token */ 1:
                    message.token = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TokenRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string token = 1; */
        if (message.token !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.token);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ngld.knossos.TokenRequest
 */
export const TokenRequest = new TokenRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TokenResponse$Type extends MessageType<TokenResponse> {
    constructor() {
        super("ngld.knossos.TokenResponse", [
            { no: 1, name: "success", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TokenResponse): TokenResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool success */ 1:
                    message.success = reader.bool();
                    break;
                case /* string token */ 2:
                    message.token = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TokenResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool success = 1; */
        if (message.success !== false)
            writer.tag(1, WireType.Varint).bool(message.success);
        /* string token = 2; */
        if (message.token !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.token);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ngld.knossos.TokenResponse
 */
export const TokenResponse = new TokenResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NullRequest$Type extends MessageType<NullRequest> {
    constructor() {
        super("ngld.knossos.NullRequest", []);
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NullRequest): NullRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: NullRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ngld.knossos.NullRequest
 */
export const NullRequest = new NullRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LoginRequest$Type extends MessageType<LoginRequest> {
    constructor() {
        super("ngld.knossos.LoginRequest", [
            { no: 1, name: "username", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "password", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LoginRequest): LoginRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string username */ 1:
                    message.username = reader.string();
                    break;
                case /* string password */ 2:
                    message.password = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LoginRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string username = 1; */
        if (message.username !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.username);
        /* string password = 2; */
        if (message.password !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.password);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ngld.knossos.LoginRequest
 */
export const LoginRequest = new LoginRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LoginResponse$Type extends MessageType<LoginResponse> {
    constructor() {
        super("ngld.knossos.LoginResponse", [
            { no: 1, name: "token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "success", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LoginResponse): LoginResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string token */ 1:
                    message.token = reader.string();
                    break;
                case /* bool success */ 2:
                    message.success = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LoginResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string token = 1; */
        if (message.token !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.token);
        /* bool success = 2; */
        if (message.success !== false)
            writer.tag(2, WireType.Varint).bool(message.success);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ngld.knossos.LoginResponse
 */
export const LoginResponse = new LoginResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SessionResponse$Type extends MessageType<SessionResponse> {
    constructor() {
        super("ngld.knossos.SessionResponse", [
            { no: 1, name: "valid", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "username", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SessionResponse): SessionResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool valid */ 1:
                    message.valid = reader.bool();
                    break;
                case /* string username */ 2:
                    message.username = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SessionResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool valid = 1; */
        if (message.valid !== false)
            writer.tag(1, WireType.Varint).bool(message.valid);
        /* string username = 2; */
        if (message.username !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.username);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ngld.knossos.SessionResponse
 */
export const SessionResponse = new SessionResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RegisterRequest$Type extends MessageType<RegisterRequest> {
    constructor() {
        super("ngld.knossos.RegisterRequest", [
            { no: 1, name: "username", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "password", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RegisterRequest): RegisterRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string username */ 1:
                    message.username = reader.string();
                    break;
                case /* string email */ 2:
                    message.email = reader.string();
                    break;
                case /* string password */ 3:
                    message.password = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RegisterRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string username = 1; */
        if (message.username !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.username);
        /* string email = 2; */
        if (message.email !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.email);
        /* string password = 3; */
        if (message.password !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.password);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ngld.knossos.RegisterRequest
 */
export const RegisterRequest = new RegisterRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StartPasswordResetRequest$Type extends MessageType<StartPasswordResetRequest> {
    constructor() {
        super("ngld.knossos.StartPasswordResetRequest", [
            { no: 1, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StartPasswordResetRequest): StartPasswordResetRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string email */ 1:
                    message.email = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: StartPasswordResetRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string email = 1; */
        if (message.email !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.email);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ngld.knossos.StartPasswordResetRequest
 */
export const StartPasswordResetRequest = new StartPasswordResetRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ResetPasswordRequest$Type extends MessageType<ResetPasswordRequest> {
    constructor() {
        super("ngld.knossos.ResetPasswordRequest", [
            { no: 1, name: "token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "password", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ResetPasswordRequest): ResetPasswordRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string token */ 1:
                    message.token = reader.string();
                    break;
                case /* string password */ 2:
                    message.password = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ResetPasswordRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string token = 1; */
        if (message.token !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.token);
        /* string password = 2; */
        if (message.password !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.password);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ngld.knossos.ResetPasswordRequest
 */
export const ResetPasswordRequest = new ResetPasswordRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ModListRequest$Type extends MessageType<ModListRequest> {
    constructor() {
        super("ngld.knossos.ModListRequest", [
            { no: 1, name: "offset", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "limit", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "query", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "sort", kind: "enum", T: () => ["ngld.knossos.ModListRequest.SortType", ModListRequest_SortType] }
        ]);
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ModListRequest): ModListRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 offset */ 1:
                    message.offset = reader.int32();
                    break;
                case /* int32 limit */ 2:
                    message.limit = reader.int32();
                    break;
                case /* string query */ 3:
                    message.query = reader.string();
                    break;
                case /* ngld.knossos.ModListRequest.SortType sort */ 4:
                    message.sort = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ModListRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 offset = 1; */
        if (message.offset !== 0)
            writer.tag(1, WireType.Varint).int32(message.offset);
        /* int32 limit = 2; */
        if (message.limit !== 0)
            writer.tag(2, WireType.Varint).int32(message.limit);
        /* string query = 3; */
        if (message.query !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.query);
        /* ngld.knossos.ModListRequest.SortType sort = 4; */
        if (message.sort !== 0)
            writer.tag(4, WireType.Varint).int32(message.sort);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ngld.knossos.ModListRequest
 */
export const ModListRequest = new ModListRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ModListItem$Type extends MessageType<ModListItem> {
    constructor() {
        super("ngld.knossos.ModListItem", [
            { no: 1, name: "modid", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "teaser", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "version", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "release_count", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ModListItem): ModListItem {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string modid */ 1:
                    message.modid = reader.string();
                    break;
                case /* string teaser */ 2:
                    message.teaser = reader.string();
                    break;
                case /* string title */ 3:
                    message.title = reader.string();
                    break;
                case /* string version */ 5:
                    message.version = reader.string();
                    break;
                case /* uint32 release_count */ 4:
                    message.releaseCount = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ModListItem, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string modid = 1; */
        if (message.modid !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.modid);
        /* string teaser = 2; */
        if (message.teaser !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.teaser);
        /* string title = 3; */
        if (message.title !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.title);
        /* string version = 5; */
        if (message.version !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.version);
        /* uint32 release_count = 4; */
        if (message.releaseCount !== 0)
            writer.tag(4, WireType.Varint).uint32(message.releaseCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ngld.knossos.ModListItem
 */
export const ModListItem = new ModListItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ModListResponse$Type extends MessageType<ModListResponse> {
    constructor() {
        super("ngld.knossos.ModListResponse", [
            { no: 1, name: "count", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "mods", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ModListItem }
        ]);
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ModListResponse): ModListResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 count */ 1:
                    message.count = reader.int32();
                    break;
                case /* repeated ngld.knossos.ModListItem mods */ 2:
                    message.mods.push(ModListItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ModListResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 count = 1; */
        if (message.count !== 0)
            writer.tag(1, WireType.Varint).int32(message.count);
        /* repeated ngld.knossos.ModListItem mods = 2; */
        for (let i = 0; i < message.mods.length; i++)
            ModListItem.internalBinaryWrite(message.mods[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ngld.knossos.ModListResponse
 */
export const ModListResponse = new ModListResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ModDetailsRequest$Type extends MessageType<ModDetailsRequest> {
    constructor() {
        super("ngld.knossos.ModDetailsRequest", [
            { no: 1, name: "modid", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "latest", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "version", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "request_downloads", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ModDetailsRequest): ModDetailsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string modid */ 1:
                    message.modid = reader.string();
                    break;
                case /* bool latest */ 2:
                    message.latest = reader.bool();
                    break;
                case /* string version */ 3:
                    message.version = reader.string();
                    break;
                case /* bool request_downloads */ 4:
                    message.requestDownloads = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ModDetailsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string modid = 1; */
        if (message.modid !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.modid);
        /* bool latest = 2; */
        if (message.latest !== false)
            writer.tag(2, WireType.Varint).bool(message.latest);
        /* string version = 3; */
        if (message.version !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.version);
        /* bool request_downloads = 4; */
        if (message.requestDownloads !== false)
            writer.tag(4, WireType.Varint).bool(message.requestDownloads);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ngld.knossos.ModDetailsRequest
 */
export const ModDetailsRequest = new ModDetailsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ModDownloadArchive$Type extends MessageType<ModDownloadArchive> {
    constructor() {
        super("ngld.knossos.ModDownloadArchive", [
            { no: 1, name: "label", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "checksum", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "size", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 4, name: "links", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ModDownloadArchive): ModDownloadArchive {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string label */ 1:
                    message.label = reader.string();
                    break;
                case /* string checksum */ 2:
                    message.checksum = reader.string();
                    break;
                case /* uint32 size */ 3:
                    message.size = reader.uint32();
                    break;
                case /* repeated string links */ 4:
                    message.links.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ModDownloadArchive, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string label = 1; */
        if (message.label !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.label);
        /* string checksum = 2; */
        if (message.checksum !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.checksum);
        /* uint32 size = 3; */
        if (message.size !== 0)
            writer.tag(3, WireType.Varint).uint32(message.size);
        /* repeated string links = 4; */
        for (let i = 0; i < message.links.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.links[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ngld.knossos.ModDownloadArchive
 */
export const ModDownloadArchive = new ModDownloadArchive$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ModDownloadPackage$Type extends MessageType<ModDownloadPackage> {
    constructor() {
        super("ngld.knossos.ModDownloadPackage", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "archives", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ModDownloadArchive }
        ]);
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ModDownloadPackage): ModDownloadPackage {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string notes */ 2:
                    message.notes = reader.string();
                    break;
                case /* repeated ngld.knossos.ModDownloadArchive archives */ 3:
                    message.archives.push(ModDownloadArchive.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ModDownloadPackage, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string notes = 2; */
        if (message.notes !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.notes);
        /* repeated ngld.knossos.ModDownloadArchive archives = 3; */
        for (let i = 0; i < message.archives.length; i++)
            ModDownloadArchive.internalBinaryWrite(message.archives[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ngld.knossos.ModDownloadPackage
 */
export const ModDownloadPackage = new ModDownloadPackage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ModDetailsResponse$Type extends MessageType<ModDetailsResponse> {
    constructor() {
        super("ngld.knossos.ModDetailsResponse", [
            { no: 1, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "version", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "type", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 4, name: "stability", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 5, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "banner", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "release_thread", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "screenshots", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "videos", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "released", kind: "message", T: () => Timestamp },
            { no: 11, name: "updated", kind: "message", T: () => Timestamp },
            { no: 12, name: "versions", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "downloads", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ModDownloadPackage }
        ]);
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ModDetailsResponse): ModDetailsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string title */ 1:
                    message.title = reader.string();
                    break;
                case /* string version */ 2:
                    message.version = reader.string();
                    break;
                case /* uint32 type */ 3:
                    message.type = reader.uint32();
                    break;
                case /* uint32 stability */ 4:
                    message.stability = reader.uint32();
                    break;
                case /* string description */ 5:
                    message.description = reader.string();
                    break;
                case /* string banner */ 6:
                    message.banner = reader.string();
                    break;
                case /* string release_thread */ 7:
                    message.releaseThread = reader.string();
                    break;
                case /* repeated string screenshots */ 8:
                    message.screenshots.push(reader.string());
                    break;
                case /* repeated string videos */ 9:
                    message.videos.push(reader.string());
                    break;
                case /* google.protobuf.Timestamp released */ 10:
                    message.released = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.released);
                    break;
                case /* google.protobuf.Timestamp updated */ 11:
                    message.updated = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updated);
                    break;
                case /* repeated string versions */ 12:
                    message.versions.push(reader.string());
                    break;
                case /* repeated ngld.knossos.ModDownloadPackage downloads */ 13:
                    message.downloads.push(ModDownloadPackage.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ModDetailsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string title = 1; */
        if (message.title !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.title);
        /* string version = 2; */
        if (message.version !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.version);
        /* uint32 type = 3; */
        if (message.type !== 0)
            writer.tag(3, WireType.Varint).uint32(message.type);
        /* uint32 stability = 4; */
        if (message.stability !== 0)
            writer.tag(4, WireType.Varint).uint32(message.stability);
        /* string description = 5; */
        if (message.description !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.description);
        /* string banner = 6; */
        if (message.banner !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.banner);
        /* string release_thread = 7; */
        if (message.releaseThread !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.releaseThread);
        /* repeated string screenshots = 8; */
        for (let i = 0; i < message.screenshots.length; i++)
            writer.tag(8, WireType.LengthDelimited).string(message.screenshots[i]);
        /* repeated string videos = 9; */
        for (let i = 0; i < message.videos.length; i++)
            writer.tag(9, WireType.LengthDelimited).string(message.videos[i]);
        /* google.protobuf.Timestamp released = 10; */
        if (message.released)
            Timestamp.internalBinaryWrite(message.released, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp updated = 11; */
        if (message.updated)
            Timestamp.internalBinaryWrite(message.updated, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* repeated string versions = 12; */
        for (let i = 0; i < message.versions.length; i++)
            writer.tag(12, WireType.LengthDelimited).string(message.versions[i]);
        /* repeated ngld.knossos.ModDownloadPackage downloads = 13; */
        for (let i = 0; i < message.downloads.length; i++)
            ModDownloadPackage.internalBinaryWrite(message.downloads[i], writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ngld.knossos.ModDetailsResponse
 */
export const ModDetailsResponse = new ModDetailsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ModInstallRequest$Type extends MessageType<ModInstallRequest> {
    constructor() {
        super("ngld.knossos.ModInstallRequest", [
            { no: 1, name: "modid", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "version", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ModInstallRequest): ModInstallRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string modid */ 1:
                    message.modid = reader.string();
                    break;
                case /* string version */ 2:
                    message.version = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ModInstallRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string modid = 1; */
        if (message.modid !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.modid);
        /* string version = 2; */
        if (message.version !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.version);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ngld.knossos.ModInstallRequest
 */
export const ModInstallRequest = new ModInstallRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ModInstallResponse$Type extends MessageType<ModInstallResponse> {
    constructor() {
        super("ngld.knossos.ModInstallResponse", [
            { no: 1, name: "error", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "releases", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Release }
        ]);
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ModInstallResponse): ModInstallResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string error */ 1:
                    message.error = reader.string();
                    break;
                case /* repeated ngld.knossos.Release releases */ 2:
                    message.releases.push(Release.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ModInstallResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string error = 1; */
        if (message.error !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.error);
        /* repeated ngld.knossos.Release releases = 2; */
        for (let i = 0; i < message.releases.length; i++)
            Release.internalBinaryWrite(message.releases[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ngld.knossos.ModInstallResponse
 */
export const ModInstallResponse = new ModInstallResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VersionsResponse$Type extends MessageType<VersionsResponse> {
    constructor() {
        super("ngld.knossos.VersionsResponse", [
            { no: 1, name: "versions", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } }
        ]);
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: VersionsResponse): VersionsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, string> versions */ 1:
                    this.binaryReadMap1(message.versions, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: VersionsResponse["versions"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof VersionsResponse["versions"] | undefined, val: VersionsResponse["versions"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field ngld.knossos.VersionsResponse.versions");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: VersionsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, string> versions = 1; */
        for (let k of Object.keys(message.versions))
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.versions[k]).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ngld.knossos.VersionsResponse
 */
export const VersionsResponse = new VersionsResponse$Type();
/**
 * @generated ServiceType for protobuf service ngld.knossos.Nebula
 */
export const Nebula = new ServiceType("ngld.knossos.Nebula", [
    { name: "Login", options: {}, I: LoginRequest, O: LoginResponse },
    { name: "CheckToken", options: {}, I: NullRequest, O: SessionResponse },
    { name: "Register", options: {}, I: RegisterRequest, O: BoolResponse },
    { name: "VerifyAccount", options: {}, I: TokenRequest, O: BoolResponse },
    { name: "StartPasswordReset", options: {}, I: StartPasswordResetRequest, O: BoolResponse },
    { name: "PreparePasswordReset", options: {}, I: TokenRequest, O: TokenResponse },
    { name: "ResetPassword", options: {}, I: ResetPasswordRequest, O: BoolResponse },
    { name: "GetModList", options: {}, I: ModListRequest, O: ModListResponse },
    { name: "GetModDetails", options: {}, I: ModDetailsRequest, O: ModDetailsResponse },
    { name: "RequestModInstall", options: {}, I: ModInstallRequest, O: ModInstallResponse },
    { name: "GetVersions", options: {}, I: NullRequest, O: VersionsResponse }
]);
