// @generated by protobuf-ts 2.0.0-alpha.27 with parameters long_type_number
// @generated from protobuf file "mod.proto" (package "ngld.knossos", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "./google/protobuf/timestamp";
/**
 * Utilities
 *
 * @generated from protobuf message ngld.knossos.Checksum
 */
export interface Checksum {
    /**
     * @generated from protobuf field: string algo = 1;
     */
    algo: string;
    /**
     * @generated from protobuf field: bytes digest = 2;
     */
    digest: Uint8Array;
}
/**
 * @generated from protobuf message ngld.knossos.FileRef
 */
export interface FileRef {
    /**
     * @generated from protobuf field: string fileid = 1;
     */
    fileid: string;
    /**
     * @generated from protobuf field: repeated string urls = 2;
     */
    urls: string[];
}
/**
 * @generated from protobuf message ngld.knossos.CpuSpec
 */
export interface CpuSpec {
    /**
     * @generated from protobuf field: repeated string required_features = 1;
     */
    requiredFeatures: string[];
}
/**
 * @generated from protobuf message ngld.knossos.Mod
 */
export interface Mod {
    /**
     * @generated from protobuf field: string modid = 1;
     */
    modid: string;
    /**
     * @generated from protobuf field: string title = 2;
     */
    title: string;
    /**
     * @generated from protobuf field: ngld.knossos.ModType type = 3;
     */
    type: ModType;
    /**
     * @generated from protobuf field: string parent = 4;
     */
    parent: string;
    /**
     * @generated from protobuf field: repeated string tags = 5;
     */
    tags: string[];
}
/**
 * @generated from protobuf message ngld.knossos.ModMeta
 */
export interface ModMeta {
    /**
     * @generated from protobuf field: string modid = 1;
     */
    modid: string;
    /**
     * @generated from protobuf field: string title = 2;
     */
    title: string;
    /**
     * @generated from protobuf field: ngld.knossos.ModType type = 4;
     */
    type: ModType;
    /**
     * @generated from protobuf field: string parent = 5;
     */
    parent: string;
    /**
     * @generated from protobuf field: repeated string tags = 3;
     */
    tags: string[];
}
/**
 * @generated from protobuf message ngld.knossos.Release
 */
export interface Release {
    /**
     * core meta
     *
     * @generated from protobuf field: string modid = 1;
     */
    modid: string;
    /**
     * @generated from protobuf field: string version = 2;
     */
    version: string;
    /**
     * @generated from protobuf field: string folder = 7;
     */
    folder: string;
    /**
     * @generated from protobuf field: ngld.knossos.ReleaseStability stability = 3;
     */
    stability: ReleaseStability;
    /**
     * mod page
     *
     * @generated from protobuf field: string description = 10;
     */
    description: string;
    /**
     * @generated from protobuf field: ngld.knossos.FileRef teaser = 11;
     */
    teaser?: FileRef;
    /**
     * @generated from protobuf field: ngld.knossos.FileRef banner = 12;
     */
    banner?: FileRef;
    /**
     * @generated from protobuf field: string release_thread = 13;
     */
    releaseThread: string;
    /**
     * @generated from protobuf field: repeated ngld.knossos.FileRef screenshots = 14;
     */
    screenshots: FileRef[];
    /**
     * @generated from protobuf field: repeated string videos = 15;
     */
    videos: string[];
    /**
     * @generated from protobuf field: google.protobuf.Timestamp released = 16;
     */
    released?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp updated = 17;
     */
    updated?: Timestamp;
    /**
     * install
     *
     * @generated from protobuf field: string notes = 20;
     */
    notes: string;
    /**
     * launch
     *
     * @generated from protobuf field: string cmdline = 21;
     */
    cmdline: string;
    /**
     * @generated from protobuf field: repeated string mod_order = 24;
     */
    modOrder: string[];
    /**
     * @generated from protobuf field: map<string, string> dependency_snapshot = 22;
     */
    dependencySnapshot: {
        [key: string]: string;
    };
    /**
     * @generated from protobuf field: bool snapshot_modified = 23;
     */
    snapshotModified: boolean;
    /**
     * content
     *
     * @generated from protobuf field: repeated ngld.knossos.Package packages = 4;
     */
    packages: Package[];
}
/**
 * @generated from protobuf message ngld.knossos.Dependency
 */
export interface Dependency {
    /**
     * @generated from protobuf field: string modid = 1;
     */
    modid: string;
    /**
     * @generated from protobuf field: string constraint = 2;
     */
    constraint: string;
    /**
     * @generated from protobuf field: repeated string packages = 3;
     */
    packages: string[];
}
/**
 * @generated from protobuf message ngld.knossos.EngineExecutable
 */
export interface EngineExecutable {
    /**
     * @generated from protobuf field: string path = 1;
     */
    path: string;
    /**
     * @generated from protobuf field: string label = 2;
     */
    label: string;
    /**
     * @generated from protobuf field: uint32 priority = 3;
     */
    priority: number;
    /**
     * @generated from protobuf field: bool debug = 4;
     */
    debug: boolean;
}
/**
 * @generated from protobuf message ngld.knossos.PackageArchive
 */
export interface PackageArchive {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string label = 2;
     */
    label: string;
    /**
     * @generated from protobuf field: string destination = 3;
     */
    destination: string;
    /**
     * @generated from protobuf field: ngld.knossos.Checksum checksum = 4;
     */
    checksum?: Checksum;
    /**
     * @generated from protobuf field: uint64 filesize = 5;
     */
    filesize: number;
    /**
     * @generated from protobuf field: ngld.knossos.FileRef download = 6;
     */
    download?: FileRef;
}
/**
 * @generated from protobuf message ngld.knossos.PackageFile
 */
export interface PackageFile {
    /**
     * @generated from protobuf field: string path = 1;
     */
    path: string;
    /**
     * archive id
     *
     * @generated from protobuf field: string archive = 2;
     */
    archive: string;
    /**
     * @generated from protobuf field: string archive_path = 3;
     */
    archivePath: string;
    /**
     * @generated from protobuf field: uint32 filesize = 5;
     */
    filesize: number;
    /**
     * @generated from protobuf field: ngld.knossos.Checksum checksum = 4;
     */
    checksum?: Checksum;
}
/**
 * @generated from protobuf message ngld.knossos.Package
 */
export interface Package {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: string folder = 2;
     */
    folder: string;
    /**
     * @generated from protobuf field: string notes = 3;
     */
    notes: string;
    /**
     * @generated from protobuf field: ngld.knossos.PackageType type = 4;
     */
    type: PackageType;
    /**
     * @generated from protobuf field: ngld.knossos.CpuSpec cpu_spec = 5;
     */
    cpuSpec?: CpuSpec;
    /**
     * @generated from protobuf field: bool knossos_vp = 6;
     */
    knossosVp: boolean;
    /**
     * @generated from protobuf field: repeated ngld.knossos.Dependency dependencies = 10;
     */
    dependencies: Dependency[];
    /**
     * @generated from protobuf field: repeated ngld.knossos.PackageArchive archives = 11;
     */
    archives: PackageArchive[];
    /**
     * @generated from protobuf field: repeated ngld.knossos.PackageFile files = 12;
     */
    files: PackageFile[];
    /**
     * @generated from protobuf field: repeated ngld.knossos.EngineExecutable executables = 20;
     */
    executables: EngineExecutable[];
}
/**
 * Core types
 *
 * @generated from protobuf enum ngld.knossos.ModType
 */
export enum ModType {
    /**
     * @generated from protobuf enum value: MOD = 0;
     */
    MOD = 0,
    /**
     * @generated from protobuf enum value: TOTAL_CONVERSION = 1;
     */
    TOTAL_CONVERSION = 1,
    /**
     * @generated from protobuf enum value: ENGINE = 2;
     */
    ENGINE = 2,
    /**
     * @generated from protobuf enum value: TOOL = 3;
     */
    TOOL = 3,
    /**
     * @generated from protobuf enum value: EXTENSION = 4;
     */
    EXTENSION = 4
}
/**
 * @generated from protobuf enum ngld.knossos.ReleaseStability
 */
export enum ReleaseStability {
    /**
     * @generated from protobuf enum value: STABLE = 0;
     */
    STABLE = 0,
    /**
     * @generated from protobuf enum value: RC = 1;
     */
    RC = 1,
    /**
     * @generated from protobuf enum value: NIGHTLY = 2;
     */
    NIGHTLY = 2
}
/**
 * @generated from protobuf enum ngld.knossos.PackageType
 */
export enum PackageType {
    /**
     * @generated from protobuf enum value: REQUIRED = 0;
     */
    REQUIRED = 0,
    /**
     * @generated from protobuf enum value: RECOMMENDED = 1;
     */
    RECOMMENDED = 1,
    /**
     * @generated from protobuf enum value: OPTIONAL = 2;
     */
    OPTIONAL = 2
}
// @generated message type with reflection information, may provide speed optimized methods
class Checksum$Type extends MessageType<Checksum> {
    constructor() {
        super("ngld.knossos.Checksum", [
            { no: 1, name: "algo", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "digest", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Checksum): Checksum {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string algo */ 1:
                    message.algo = reader.string();
                    break;
                case /* bytes digest */ 2:
                    message.digest = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Checksum, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string algo = 1; */
        if (message.algo !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.algo);
        /* bytes digest = 2; */
        if (message.digest.length)
            writer.tag(2, WireType.LengthDelimited).bytes(message.digest);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ngld.knossos.Checksum
 */
export const Checksum = new Checksum$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FileRef$Type extends MessageType<FileRef> {
    constructor() {
        super("ngld.knossos.FileRef", [
            { no: 1, name: "fileid", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "urls", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FileRef): FileRef {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string fileid */ 1:
                    message.fileid = reader.string();
                    break;
                case /* repeated string urls */ 2:
                    message.urls.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FileRef, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string fileid = 1; */
        if (message.fileid !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.fileid);
        /* repeated string urls = 2; */
        for (let i = 0; i < message.urls.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.urls[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ngld.knossos.FileRef
 */
export const FileRef = new FileRef$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CpuSpec$Type extends MessageType<CpuSpec> {
    constructor() {
        super("ngld.knossos.CpuSpec", [
            { no: 1, name: "required_features", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CpuSpec): CpuSpec {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string required_features */ 1:
                    message.requiredFeatures.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CpuSpec, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string required_features = 1; */
        for (let i = 0; i < message.requiredFeatures.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.requiredFeatures[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ngld.knossos.CpuSpec
 */
export const CpuSpec = new CpuSpec$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Mod$Type extends MessageType<Mod> {
    constructor() {
        super("ngld.knossos.Mod", [
            { no: 1, name: "modid", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "type", kind: "enum", T: () => ["ngld.knossos.ModType", ModType] },
            { no: 4, name: "parent", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "tags", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Mod): Mod {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string modid */ 1:
                    message.modid = reader.string();
                    break;
                case /* string title */ 2:
                    message.title = reader.string();
                    break;
                case /* ngld.knossos.ModType type */ 3:
                    message.type = reader.int32();
                    break;
                case /* string parent */ 4:
                    message.parent = reader.string();
                    break;
                case /* repeated string tags */ 5:
                    message.tags.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Mod, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string modid = 1; */
        if (message.modid !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.modid);
        /* string title = 2; */
        if (message.title !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.title);
        /* ngld.knossos.ModType type = 3; */
        if (message.type !== 0)
            writer.tag(3, WireType.Varint).int32(message.type);
        /* string parent = 4; */
        if (message.parent !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.parent);
        /* repeated string tags = 5; */
        for (let i = 0; i < message.tags.length; i++)
            writer.tag(5, WireType.LengthDelimited).string(message.tags[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ngld.knossos.Mod
 */
export const Mod = new Mod$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ModMeta$Type extends MessageType<ModMeta> {
    constructor() {
        super("ngld.knossos.ModMeta", [
            { no: 1, name: "modid", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "type", kind: "enum", T: () => ["ngld.knossos.ModType", ModType] },
            { no: 5, name: "parent", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "tags", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ModMeta): ModMeta {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string modid */ 1:
                    message.modid = reader.string();
                    break;
                case /* string title */ 2:
                    message.title = reader.string();
                    break;
                case /* ngld.knossos.ModType type */ 4:
                    message.type = reader.int32();
                    break;
                case /* string parent */ 5:
                    message.parent = reader.string();
                    break;
                case /* repeated string tags */ 3:
                    message.tags.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ModMeta, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string modid = 1; */
        if (message.modid !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.modid);
        /* string title = 2; */
        if (message.title !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.title);
        /* ngld.knossos.ModType type = 4; */
        if (message.type !== 0)
            writer.tag(4, WireType.Varint).int32(message.type);
        /* string parent = 5; */
        if (message.parent !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.parent);
        /* repeated string tags = 3; */
        for (let i = 0; i < message.tags.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.tags[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ngld.knossos.ModMeta
 */
export const ModMeta = new ModMeta$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Release$Type extends MessageType<Release> {
    constructor() {
        super("ngld.knossos.Release", [
            { no: 1, name: "modid", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "version", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "folder", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "stability", kind: "enum", T: () => ["ngld.knossos.ReleaseStability", ReleaseStability] },
            { no: 10, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "teaser", kind: "message", T: () => FileRef },
            { no: 12, name: "banner", kind: "message", T: () => FileRef },
            { no: 13, name: "release_thread", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "screenshots", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => FileRef },
            { no: 15, name: "videos", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "released", kind: "message", T: () => Timestamp },
            { no: 17, name: "updated", kind: "message", T: () => Timestamp },
            { no: 20, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 21, name: "cmdline", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 24, name: "mod_order", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 22, name: "dependency_snapshot", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 23, name: "snapshot_modified", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "packages", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Package }
        ]);
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Release): Release {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string modid */ 1:
                    message.modid = reader.string();
                    break;
                case /* string version */ 2:
                    message.version = reader.string();
                    break;
                case /* string folder */ 7:
                    message.folder = reader.string();
                    break;
                case /* ngld.knossos.ReleaseStability stability */ 3:
                    message.stability = reader.int32();
                    break;
                case /* string description */ 10:
                    message.description = reader.string();
                    break;
                case /* ngld.knossos.FileRef teaser */ 11:
                    message.teaser = FileRef.internalBinaryRead(reader, reader.uint32(), options, message.teaser);
                    break;
                case /* ngld.knossos.FileRef banner */ 12:
                    message.banner = FileRef.internalBinaryRead(reader, reader.uint32(), options, message.banner);
                    break;
                case /* string release_thread */ 13:
                    message.releaseThread = reader.string();
                    break;
                case /* repeated ngld.knossos.FileRef screenshots */ 14:
                    message.screenshots.push(FileRef.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated string videos */ 15:
                    message.videos.push(reader.string());
                    break;
                case /* google.protobuf.Timestamp released */ 16:
                    message.released = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.released);
                    break;
                case /* google.protobuf.Timestamp updated */ 17:
                    message.updated = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updated);
                    break;
                case /* string notes */ 20:
                    message.notes = reader.string();
                    break;
                case /* string cmdline */ 21:
                    message.cmdline = reader.string();
                    break;
                case /* repeated string mod_order */ 24:
                    message.modOrder.push(reader.string());
                    break;
                case /* map<string, string> dependency_snapshot */ 22:
                    this.binaryReadMap22(message.dependencySnapshot, reader, options);
                    break;
                case /* bool snapshot_modified */ 23:
                    message.snapshotModified = reader.bool();
                    break;
                case /* repeated ngld.knossos.Package packages */ 4:
                    message.packages.push(Package.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap22(map: Release["dependencySnapshot"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof Release["dependencySnapshot"] | undefined, val: Release["dependencySnapshot"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field ngld.knossos.Release.dependency_snapshot");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: Release, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string modid = 1; */
        if (message.modid !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.modid);
        /* string version = 2; */
        if (message.version !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.version);
        /* string folder = 7; */
        if (message.folder !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.folder);
        /* ngld.knossos.ReleaseStability stability = 3; */
        if (message.stability !== 0)
            writer.tag(3, WireType.Varint).int32(message.stability);
        /* string description = 10; */
        if (message.description !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.description);
        /* ngld.knossos.FileRef teaser = 11; */
        if (message.teaser)
            FileRef.internalBinaryWrite(message.teaser, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* ngld.knossos.FileRef banner = 12; */
        if (message.banner)
            FileRef.internalBinaryWrite(message.banner, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* string release_thread = 13; */
        if (message.releaseThread !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.releaseThread);
        /* repeated ngld.knossos.FileRef screenshots = 14; */
        for (let i = 0; i < message.screenshots.length; i++)
            FileRef.internalBinaryWrite(message.screenshots[i], writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* repeated string videos = 15; */
        for (let i = 0; i < message.videos.length; i++)
            writer.tag(15, WireType.LengthDelimited).string(message.videos[i]);
        /* google.protobuf.Timestamp released = 16; */
        if (message.released)
            Timestamp.internalBinaryWrite(message.released, writer.tag(16, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp updated = 17; */
        if (message.updated)
            Timestamp.internalBinaryWrite(message.updated, writer.tag(17, WireType.LengthDelimited).fork(), options).join();
        /* string notes = 20; */
        if (message.notes !== "")
            writer.tag(20, WireType.LengthDelimited).string(message.notes);
        /* string cmdline = 21; */
        if (message.cmdline !== "")
            writer.tag(21, WireType.LengthDelimited).string(message.cmdline);
        /* repeated string mod_order = 24; */
        for (let i = 0; i < message.modOrder.length; i++)
            writer.tag(24, WireType.LengthDelimited).string(message.modOrder[i]);
        /* map<string, string> dependency_snapshot = 22; */
        for (let k of Object.keys(message.dependencySnapshot))
            writer.tag(22, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.dependencySnapshot[k]).join();
        /* bool snapshot_modified = 23; */
        if (message.snapshotModified !== false)
            writer.tag(23, WireType.Varint).bool(message.snapshotModified);
        /* repeated ngld.knossos.Package packages = 4; */
        for (let i = 0; i < message.packages.length; i++)
            Package.internalBinaryWrite(message.packages[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ngld.knossos.Release
 */
export const Release = new Release$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Dependency$Type extends MessageType<Dependency> {
    constructor() {
        super("ngld.knossos.Dependency", [
            { no: 1, name: "modid", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "constraint", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "packages", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Dependency): Dependency {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string modid */ 1:
                    message.modid = reader.string();
                    break;
                case /* string constraint */ 2:
                    message.constraint = reader.string();
                    break;
                case /* repeated string packages */ 3:
                    message.packages.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Dependency, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string modid = 1; */
        if (message.modid !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.modid);
        /* string constraint = 2; */
        if (message.constraint !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.constraint);
        /* repeated string packages = 3; */
        for (let i = 0; i < message.packages.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.packages[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ngld.knossos.Dependency
 */
export const Dependency = new Dependency$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EngineExecutable$Type extends MessageType<EngineExecutable> {
    constructor() {
        super("ngld.knossos.EngineExecutable", [
            { no: 1, name: "path", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "label", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "priority", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 4, name: "debug", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EngineExecutable): EngineExecutable {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string path */ 1:
                    message.path = reader.string();
                    break;
                case /* string label */ 2:
                    message.label = reader.string();
                    break;
                case /* uint32 priority */ 3:
                    message.priority = reader.uint32();
                    break;
                case /* bool debug */ 4:
                    message.debug = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EngineExecutable, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string path = 1; */
        if (message.path !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.path);
        /* string label = 2; */
        if (message.label !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.label);
        /* uint32 priority = 3; */
        if (message.priority !== 0)
            writer.tag(3, WireType.Varint).uint32(message.priority);
        /* bool debug = 4; */
        if (message.debug !== false)
            writer.tag(4, WireType.Varint).bool(message.debug);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ngld.knossos.EngineExecutable
 */
export const EngineExecutable = new EngineExecutable$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PackageArchive$Type extends MessageType<PackageArchive> {
    constructor() {
        super("ngld.knossos.PackageArchive", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "label", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "destination", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "checksum", kind: "message", T: () => Checksum },
            { no: 5, name: "filesize", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "download", kind: "message", T: () => FileRef }
        ]);
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PackageArchive): PackageArchive {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string label */ 2:
                    message.label = reader.string();
                    break;
                case /* string destination */ 3:
                    message.destination = reader.string();
                    break;
                case /* ngld.knossos.Checksum checksum */ 4:
                    message.checksum = Checksum.internalBinaryRead(reader, reader.uint32(), options, message.checksum);
                    break;
                case /* uint64 filesize */ 5:
                    message.filesize = reader.uint64().toNumber();
                    break;
                case /* ngld.knossos.FileRef download */ 6:
                    message.download = FileRef.internalBinaryRead(reader, reader.uint32(), options, message.download);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PackageArchive, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string label = 2; */
        if (message.label !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.label);
        /* string destination = 3; */
        if (message.destination !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.destination);
        /* ngld.knossos.Checksum checksum = 4; */
        if (message.checksum)
            Checksum.internalBinaryWrite(message.checksum, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* uint64 filesize = 5; */
        if (message.filesize !== 0)
            writer.tag(5, WireType.Varint).uint64(message.filesize);
        /* ngld.knossos.FileRef download = 6; */
        if (message.download)
            FileRef.internalBinaryWrite(message.download, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ngld.knossos.PackageArchive
 */
export const PackageArchive = new PackageArchive$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PackageFile$Type extends MessageType<PackageFile> {
    constructor() {
        super("ngld.knossos.PackageFile", [
            { no: 1, name: "path", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "archive", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "archive_path", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "filesize", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 4, name: "checksum", kind: "message", T: () => Checksum }
        ]);
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PackageFile): PackageFile {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string path */ 1:
                    message.path = reader.string();
                    break;
                case /* string archive */ 2:
                    message.archive = reader.string();
                    break;
                case /* string archive_path */ 3:
                    message.archivePath = reader.string();
                    break;
                case /* uint32 filesize */ 5:
                    message.filesize = reader.uint32();
                    break;
                case /* ngld.knossos.Checksum checksum */ 4:
                    message.checksum = Checksum.internalBinaryRead(reader, reader.uint32(), options, message.checksum);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PackageFile, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string path = 1; */
        if (message.path !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.path);
        /* string archive = 2; */
        if (message.archive !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.archive);
        /* string archive_path = 3; */
        if (message.archivePath !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.archivePath);
        /* uint32 filesize = 5; */
        if (message.filesize !== 0)
            writer.tag(5, WireType.Varint).uint32(message.filesize);
        /* ngld.knossos.Checksum checksum = 4; */
        if (message.checksum)
            Checksum.internalBinaryWrite(message.checksum, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ngld.knossos.PackageFile
 */
export const PackageFile = new PackageFile$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Package$Type extends MessageType<Package> {
    constructor() {
        super("ngld.knossos.Package", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "folder", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "type", kind: "enum", T: () => ["ngld.knossos.PackageType", PackageType] },
            { no: 5, name: "cpu_spec", kind: "message", T: () => CpuSpec },
            { no: 6, name: "knossos_vp", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 10, name: "dependencies", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Dependency },
            { no: 11, name: "archives", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PackageArchive },
            { no: 12, name: "files", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PackageFile },
            { no: 20, name: "executables", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => EngineExecutable }
        ]);
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Package): Package {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string folder */ 2:
                    message.folder = reader.string();
                    break;
                case /* string notes */ 3:
                    message.notes = reader.string();
                    break;
                case /* ngld.knossos.PackageType type */ 4:
                    message.type = reader.int32();
                    break;
                case /* ngld.knossos.CpuSpec cpu_spec */ 5:
                    message.cpuSpec = CpuSpec.internalBinaryRead(reader, reader.uint32(), options, message.cpuSpec);
                    break;
                case /* bool knossos_vp */ 6:
                    message.knossosVp = reader.bool();
                    break;
                case /* repeated ngld.knossos.Dependency dependencies */ 10:
                    message.dependencies.push(Dependency.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated ngld.knossos.PackageArchive archives */ 11:
                    message.archives.push(PackageArchive.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated ngld.knossos.PackageFile files */ 12:
                    message.files.push(PackageFile.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated ngld.knossos.EngineExecutable executables */ 20:
                    message.executables.push(EngineExecutable.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Package, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string folder = 2; */
        if (message.folder !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.folder);
        /* string notes = 3; */
        if (message.notes !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.notes);
        /* ngld.knossos.PackageType type = 4; */
        if (message.type !== 0)
            writer.tag(4, WireType.Varint).int32(message.type);
        /* ngld.knossos.CpuSpec cpu_spec = 5; */
        if (message.cpuSpec)
            CpuSpec.internalBinaryWrite(message.cpuSpec, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* bool knossos_vp = 6; */
        if (message.knossosVp !== false)
            writer.tag(6, WireType.Varint).bool(message.knossosVp);
        /* repeated ngld.knossos.Dependency dependencies = 10; */
        for (let i = 0; i < message.dependencies.length; i++)
            Dependency.internalBinaryWrite(message.dependencies[i], writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* repeated ngld.knossos.PackageArchive archives = 11; */
        for (let i = 0; i < message.archives.length; i++)
            PackageArchive.internalBinaryWrite(message.archives[i], writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* repeated ngld.knossos.PackageFile files = 12; */
        for (let i = 0; i < message.files.length; i++)
            PackageFile.internalBinaryWrite(message.files[i], writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* repeated ngld.knossos.EngineExecutable executables = 20; */
        for (let i = 0; i < message.executables.length; i++)
            EngineExecutable.internalBinaryWrite(message.executables[i], writer.tag(20, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ngld.knossos.Package
 */
export const Package = new Package$Type();
