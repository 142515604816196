// @generated by protobuf-ts 2.0.0-alpha.27 with parameters long_type_number
// @generated from protobuf file "service.proto" (package "ngld.knossos", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { Nebula } from "./service";
import type { VersionsResponse } from "./service";
import type { ModInstallResponse } from "./service";
import type { ModInstallRequest } from "./service";
import type { ModDetailsResponse } from "./service";
import type { ModDetailsRequest } from "./service";
import type { ModListResponse } from "./service";
import type { ModListRequest } from "./service";
import type { ResetPasswordRequest } from "./service";
import type { TokenResponse } from "./service";
import type { StartPasswordResetRequest } from "./service";
import type { TokenRequest } from "./service";
import type { BoolResponse } from "./service";
import type { RegisterRequest } from "./service";
import type { SessionResponse } from "./service";
import type { NullRequest } from "./service";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { LoginResponse } from "./service";
import type { LoginRequest } from "./service";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service ngld.knossos.Nebula
 */
export interface INebulaClient {
    /**
     * @generated from protobuf rpc: Login(ngld.knossos.LoginRequest) returns (ngld.knossos.LoginResponse);
     */
    login(input: LoginRequest, options?: RpcOptions): UnaryCall<LoginRequest, LoginResponse>;
    /**
     * @generated from protobuf rpc: CheckToken(ngld.knossos.NullRequest) returns (ngld.knossos.SessionResponse);
     */
    checkToken(input: NullRequest, options?: RpcOptions): UnaryCall<NullRequest, SessionResponse>;
    /**
     * @generated from protobuf rpc: Register(ngld.knossos.RegisterRequest) returns (ngld.knossos.BoolResponse);
     */
    register(input: RegisterRequest, options?: RpcOptions): UnaryCall<RegisterRequest, BoolResponse>;
    /**
     * @generated from protobuf rpc: VerifyAccount(ngld.knossos.TokenRequest) returns (ngld.knossos.BoolResponse);
     */
    verifyAccount(input: TokenRequest, options?: RpcOptions): UnaryCall<TokenRequest, BoolResponse>;
    /**
     * @generated from protobuf rpc: StartPasswordReset(ngld.knossos.StartPasswordResetRequest) returns (ngld.knossos.BoolResponse);
     */
    startPasswordReset(input: StartPasswordResetRequest, options?: RpcOptions): UnaryCall<StartPasswordResetRequest, BoolResponse>;
    /**
     * @generated from protobuf rpc: PreparePasswordReset(ngld.knossos.TokenRequest) returns (ngld.knossos.TokenResponse);
     */
    preparePasswordReset(input: TokenRequest, options?: RpcOptions): UnaryCall<TokenRequest, TokenResponse>;
    /**
     * @generated from protobuf rpc: ResetPassword(ngld.knossos.ResetPasswordRequest) returns (ngld.knossos.BoolResponse);
     */
    resetPassword(input: ResetPasswordRequest, options?: RpcOptions): UnaryCall<ResetPasswordRequest, BoolResponse>;
    /**
     * @generated from protobuf rpc: GetModList(ngld.knossos.ModListRequest) returns (ngld.knossos.ModListResponse);
     */
    getModList(input: ModListRequest, options?: RpcOptions): UnaryCall<ModListRequest, ModListResponse>;
    /**
     * @generated from protobuf rpc: GetModDetails(ngld.knossos.ModDetailsRequest) returns (ngld.knossos.ModDetailsResponse);
     */
    getModDetails(input: ModDetailsRequest, options?: RpcOptions): UnaryCall<ModDetailsRequest, ModDetailsResponse>;
    /**
     * @generated from protobuf rpc: RequestModInstall(ngld.knossos.ModInstallRequest) returns (ngld.knossos.ModInstallResponse);
     */
    requestModInstall(input: ModInstallRequest, options?: RpcOptions): UnaryCall<ModInstallRequest, ModInstallResponse>;
    /**
     * @generated from protobuf rpc: GetVersions(ngld.knossos.NullRequest) returns (ngld.knossos.VersionsResponse);
     */
    getVersions(input: NullRequest, options?: RpcOptions): UnaryCall<NullRequest, VersionsResponse>;
}
/**
 * @generated from protobuf service ngld.knossos.Nebula
 */
export class NebulaClient implements INebulaClient, ServiceInfo {
    typeName = Nebula.typeName;
    methods = Nebula.methods;
    options = Nebula.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: Login(ngld.knossos.LoginRequest) returns (ngld.knossos.LoginResponse);
     */
    login(input: LoginRequest, options?: RpcOptions): UnaryCall<LoginRequest, LoginResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<LoginRequest, LoginResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CheckToken(ngld.knossos.NullRequest) returns (ngld.knossos.SessionResponse);
     */
    checkToken(input: NullRequest, options?: RpcOptions): UnaryCall<NullRequest, SessionResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<NullRequest, SessionResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Register(ngld.knossos.RegisterRequest) returns (ngld.knossos.BoolResponse);
     */
    register(input: RegisterRequest, options?: RpcOptions): UnaryCall<RegisterRequest, BoolResponse> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<RegisterRequest, BoolResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: VerifyAccount(ngld.knossos.TokenRequest) returns (ngld.knossos.BoolResponse);
     */
    verifyAccount(input: TokenRequest, options?: RpcOptions): UnaryCall<TokenRequest, BoolResponse> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<TokenRequest, BoolResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: StartPasswordReset(ngld.knossos.StartPasswordResetRequest) returns (ngld.knossos.BoolResponse);
     */
    startPasswordReset(input: StartPasswordResetRequest, options?: RpcOptions): UnaryCall<StartPasswordResetRequest, BoolResponse> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<StartPasswordResetRequest, BoolResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: PreparePasswordReset(ngld.knossos.TokenRequest) returns (ngld.knossos.TokenResponse);
     */
    preparePasswordReset(input: TokenRequest, options?: RpcOptions): UnaryCall<TokenRequest, TokenResponse> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<TokenRequest, TokenResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ResetPassword(ngld.knossos.ResetPasswordRequest) returns (ngld.knossos.BoolResponse);
     */
    resetPassword(input: ResetPasswordRequest, options?: RpcOptions): UnaryCall<ResetPasswordRequest, BoolResponse> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<ResetPasswordRequest, BoolResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetModList(ngld.knossos.ModListRequest) returns (ngld.knossos.ModListResponse);
     */
    getModList(input: ModListRequest, options?: RpcOptions): UnaryCall<ModListRequest, ModListResponse> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<ModListRequest, ModListResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetModDetails(ngld.knossos.ModDetailsRequest) returns (ngld.knossos.ModDetailsResponse);
     */
    getModDetails(input: ModDetailsRequest, options?: RpcOptions): UnaryCall<ModDetailsRequest, ModDetailsResponse> {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept<ModDetailsRequest, ModDetailsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: RequestModInstall(ngld.knossos.ModInstallRequest) returns (ngld.knossos.ModInstallResponse);
     */
    requestModInstall(input: ModInstallRequest, options?: RpcOptions): UnaryCall<ModInstallRequest, ModInstallResponse> {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept<ModInstallRequest, ModInstallResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetVersions(ngld.knossos.NullRequest) returns (ngld.knossos.VersionsResponse);
     */
    getVersions(input: NullRequest, options?: RpcOptions): UnaryCall<NullRequest, VersionsResponse> {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept<NullRequest, VersionsResponse>("unary", this._transport, method, opt, input);
    }
}
